.header {
  display: flex;
  justify-content: space-between;
  background-color: #f0ebe9;
  align-items: center;
}

header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
}

.header-logo {
  width: 120px;
  height: auto;
  padding-left: 20px;
}

.p-menuitem-link {
  background-color: transparent !important;
  /* color: white !important; */
}

.p-menuitem-link:hover {
  /* color: rgb(232, 232, 232) !important; */
}

.p-menuitem-link:focus {
  border: none;
}

.p-tabmenu-nav {
  background-color: transparent !important;
}

@media (max-width: 991px) {
  ul.p-tabview-nav li {
    width: 100% !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-color: #efefef !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #efefef !important;
    font-weight: bold;
  }
}
