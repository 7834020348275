.infos {
  background-color: white;
  padding: 20px;
}

.infos-img {
  height: 100%;
}

.infos-img > img {
  min-height: 100%;
  max-height: 450px;
  width: 100%;
  object-fit: cover;
}

.map-container {
  min-height: 300px;
  width: 100%;
}

.my-12 {
  margin: 0 0 24px 0;
}

.my-6 {
  margin: 0 0 12px 0;
}
