.section {
  padding: 2rem;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .section {
    padding: 0.5rem;
    box-sizing: border-box;
  }
}

.title {
  text-align: left;
  /* color: white; */
}

.subtitle {
  align-self: center;
  cursor: pointer;
}
