.banner {
  height: calc(100vh - 58px);
  background-color: rgb(49, 49, 49);
}

.banner-bg {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.banner-titles {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.banner-title {
  font-size: 5rem;
  padding: 0 3rem;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .banner-title {
    font-size: 3rem;
  }
}
