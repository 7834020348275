.filled {
  background-color: rgb(44, 148, 218);
}

.svg-hall:hover {
  fill-opacity: 100% !important;
  transition: fill-opacity 0.2s ease-in-out;
  -moz-transition: fill-opacity 0.2s ease-in-out;
  -webkit-transition: fill-opacity 0.2s ease-in-out;
  cursor: pointer;
}

.svg-hall {
  fill-opacity: 50% !important;
  transition: fill-opacity 0.2s ease-in-out;
  -moz-transition: fill-opacity 0.2s ease-in-out;
  -webkit-transition: fill-opacity 0.2s ease-in-out;
}

.p-menubar-root-list {
  justify-content: center;
  width: 90vw !important;
  right: 0 !important;
  left: inherit !important;
}

.floormapNav {
  display: flex;
  flex-direction: column-reverse;
}

@media only screen and (max-width: 960px) {
  .floormapNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

#Road1,
#Road2,
#Road3 {
  fill: transparent;
}
