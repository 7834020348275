.summary {
  background-color: white;
  padding: 20px;
}

/* .summary-img {
  width: 50%;
} */

.summary-img > img {
  min-height: 150px;
  max-height: 450px;
  width: 100%;
  object-fit: cover;
}

.summary-avatar {
  background-color: var(--primary-color) !important;
  color: var(--primary-color-text) !important;
}

.summary-title {
  font-size: 3rem;
  margin: 0;
}
@media only screen and (max-width: 600px) {
  .summary-title {
    font-size: 2rem;
    margin: 0;
  }
}

.m-widht-60 {
  max-width: 60%;
}
