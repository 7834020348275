.sticky-top {
  position: sticky !important;
  top: 30px !important;
  z-index: 200 !important;
}

.p-steps-number {
  background-image: url(../../assets/botanic-step.png) !important;
  background-size: cover !important;
  color: transparent !important;
}

.p-steps-current > .p-menuitem-link > .p-steps-number {
  background-image: url(../../assets/botanic-step-active.png) !important;
  background-size: cover !important;
  color: transparent !important;
}

.p-steps-item:before {
  top: 35% !important;
}

.p-steps-item {
  min-width: 100px;
}

.p-timeline-event-opposite {
  flex: 0 !important;
  padding: 0.5rem !important;
}

.p-panel-title {
  width: 100%;
}

.schedule-date {
  font-weight: 400;
}

.w-2rem {
  width: 3rem;
}

.h-2rem {
  height: 3rem;
}

.p-timeline-event-content {
  margin-top: -10px;
}

.p-steps {
  background-color: white;
  padding: 2rem;
}

.p-panel-content {
  margin-bottom: 22px;
  padding: 0 !important;
}

.horizontal-scroll {
  overflow-x: scroll;
}

.card {
  background-color: white;
  border-radius: 16px;
  padding: 20px;
}

.schedule-marker {
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  /* RESPONSIVE */
  .p-timeline-event-opposite {
    padding: 0 !important;
  }

  .p-timeline-event-separator {
    flex: inherit !important;
    width: 100% !important;
  }

  .p-timeline-event-content {
    padding: 0 !important;
  }

  .p-timeline-event-connector {
    min-height: 16px;
  }

  .p-panel-content {
    margin-bottom: 0;
  }
  .sticky-top {
    top: 70px !important;
  }
}

.MuiTabs-indicator {
  display: none;
}

.navBox {
  display: flex;
  justify-content: center;
}

.p-panel-header {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  background-color: white;
}
